import React from "react";
import { viewports, colors } from "../../style-vars";

const { lgOnly, mdOnly, xsOnly, smOrSmaller, mdOrSmaller } = viewports;
const { black } = colors;

const IntegrationStyles = () => (
  <style jsx global>{`
    .integrations {
      position: relative;
      margin: 40px 100px;
    }
    .integrations__override {
      margin: 40px 32px !important;
    }
    @media ${lgOnly} {
      .integrations {
        margin: 40px 80px;
      }
    }
    @media ${mdOnly} {
      .integrations {
        margin: 40px 80px;
      }
    }
    @media ${smOrSmaller} {
      .integrations {
        margin: 40px 32px;
      }
    }
    .integrations__heading {
      text-align: center;
      width: 75%;
      display: table;
      margin: 0 auto;
    }
    @media ${smOrSmaller} {
      .integrations__heading {
        width: 100%;
        text-align: left;
      }
    }
    .integrations__heading h2 {
      font-weight: 800 !important;
      margin-bottom: 24px;
    }
    .integrations__heading p {
      font-size: 16px;
      color: #445469;
    }
    .integrations__container {
      overflow: hidden;
      margin: 64px 32px 0 32px;
      position: relative;
    }
    @media ${xsOnly} {
      .integrations__container {
        margin: 64px 0 0 0;
      }
    }
    .integrations__container:after {
      content: "";
      position: absolute;
      top: 70%;
      bottom: 0;
      width: 100%;
      background: linear-gradient(to top, #ffffff, rgba(0, 0, 0, 0));
    }
    .integrations__container-opened {
      margin: 64px 32px 64px 32px;
      height: 350px;
      position: relative;
    }
    .integrations__container-opened:after {
      position: relative !important;
    }
    .integrations .cta-secondary-custom {
      margin-bottom: 30px;
      margin-top: 2px;
      font-weight: 800;
      width: auto;
      cursor: pointer;
    }
    .integrations .cta-secondary-custom img {
      transform: rotate(90deg);
      margin-left: 8px;
    }
    .integrations-boxes {
      min-height: 300px;
      margin-top: 32px;
      background-color: #f3f4f6;
      transition: ease-in-out 0.3s;
      padding: 32px 24px;
      border-radius: 12px;
    }
    .integrations-boxes:hover {
      background-color: #e8f0ff;
    }
    .integrations-boxes h3 {
      color: ${black} !important;
      line-height: 1.5 !important;
      font-size: 24px !important;
    }
    .integrations-boxes p {
      font-size: 16px;
      font-weight: 400;
      color: #6e7682;
    }
    .integrations-boxes .cta-secondary {
      padding-left: 0 !important;
      display: flex;
    }
    .integrations-boxes-img {
      transform: translateX(-8%);
      width: 90%;
      width: 100%;
      top: 20%;
      left: 7%;
    }
    .integrations-boxes-img.phone {
      top: 2% !important;
    }
    @media ${mdOrSmaller} {
      .integrations-boxes-img {
        transform: none;
        width: 35%;
        margin-bottom: 24px;
        margin-left: auto !important;
        margin-right: auto !important;
      }
      .integrations-boxes-img.register {
        margin-top: 30px !important;
        margin-bottom: 40px;
      }
      .integrations-boxes-img.phone {
        margin-top: 10px !important;
        width: 100px;
      }
    }
    @media ${smOrSmaller} {
      .integrations-boxes-img {
        width: 80%;
      }
    }
    .integrations-boxes .bg-circle {
      width: 120px;
      height: 120px;
      background-color: #bfc6d0;
      border-radius: 120px;
      position: absolute;
      transition: ease-in-out 0.3s;
    }
    @media ${mdOrSmaller} {
      .integrations-boxes .bg-circle {
        left: 50%;
        width: 200px;
        height: 200px;
        transform: translateX(-50%);
      }
    }
    .integrations-boxes:hover .bg-circle {
      background-color: #d1e1ff;
    }
    .flipped {
      transform: rotate(270deg) !important;
    }
    .integration-item {
      text-align: center;
      border-radius: 8px;
      transition: ease-in-out 0.3s;
      padding-top: 10px;
      padding-bottom: 20px;
    }
    .integration-item h3 {
      font-size: 18px !important;
      font-weight: 700 !important;
      color: ${black} !important;
      margin-top: 12px !important;
      white-space: pre-line;
      margin-bottom: 9px !important;
    }
    .integration-item span {
      color: #6e7682 !important;
      font-weight: 400 !important;
      margin-bottom: 20px !important;
    }
    .integration-item__image {
      width: 96px;
      height: 96px;
      border: 1px solid #eceef0;
      border-radius: 8px;
      margin: 0 auto;
      display: table;
      position: relative;
    }
    .integration-item__image img {
      width: 62px !important;
      height: 62px !important;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      object-fit: scale-down !important;
    }
    .integration-item__image .image-wrapper {
      height: 100%;
    }
  `}</style>
);

export default IntegrationStyles;
