import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import IntegrationsContent from "./IntegrationsContent";
import IntegrationItem from "./IntegrationItem";
import CtaSecondary from "../Buttons/secondary-cta";
import chevron from "../../images/svg/chevron_secondary.svg";
import IntegrationStyles from "./IntegrationStyles";

const { Title } = Typography;

const IntegrationsSection = (props) => {
  const {
    sectionData,
    integrationList,
    integrationOverride,
    showInt,
    showReq,
    isRetail,
  } = props;
  const { title, subtext, integrationsContent, punctuation } = sectionData;
  const [open, setOpened] = useState(false);
  const openSection = () => setOpened(!open);

  return (
    <section
      className={`integrations ${
        integrationOverride && "integrations__override"
      }`}
    >
      <div className="integrations__heading">
        <Title level={2}>
          {" "}
          {title}
          <span className="blue_dot">{punctuation || "."}</span>{" "}
        </Title>
        {subtext && <p>{subtext}</p>}
      </div>
      {integrationsContent && (
        <Row gutter={[32, 32]}>
          {integrationsContent.map((content) => (
            <Col
              key={content.title}
              lg={integrationsContent.length === 2 ? 12 : 8}
              sm={24}
            >
              <IntegrationsContent
                title={content.title}
                subtext={content.subtext}
                contentImg={content.contentImg}
                btnInfo={content.btnInfo}
                ImgClassName={content.ImgClassName}
              />
            </Col>
          ))}
        </Row>
      )}
      {integrationList && showInt && (
        <>
          <div
            className={
              !isRetail &&
              `integrations__container ${
                open && "integrations__container-opened"
              }`
            }
            style={
              !isRetail
                ? { height: `${open ? "auto" : "350px"}` }
                : { height: "auto" }
            }
          >
            <Row gutter={[32, 32]} justify="center">
              {integrationList.map((item) => (
                <Col key={item.title} lg={4} md={8} sm={12} xs={12}>
                  <IntegrationItem
                    img={item.img}
                    type={item.type}
                    title={item.title}
                  />
                </Col>
              ))}
            </Row>
          </div>
          {!isRetail && (
            <Row justify="center" align="middle">
              {showInt && (
                <Col xs={24} style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    onClick={openSection}
                    className="cta-secondary cta-secondary-custom"
                  >
                    View {open ? "Fewer" : "More"} Integrations
                    <img
                      src={chevron}
                      alt=""
                      className={open ? "flipped" : ""}
                    />
                  </button>
                </Col>
              )}
              {showReq && (
                <Col xs={24} style={{ textAlign: "center" }}>
                  <CtaSecondary
                    openInNewTab
                    ctaTitle="Request an Integration"
                    target="https://forms.gle/yHmy7zSLUFrK67ZM9"
                  />
                </Col>
              )}
            </Row>
          )}
        </>
      )}
      <IntegrationStyles />
    </section>
  );
};

IntegrationsSection.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  integrationList: PropTypes.arrayOf(PropTypes.any),
  integrationOverride: PropTypes.bool,
  showInt: PropTypes.bool,
  showReq: PropTypes.bool,
  isRetail: PropTypes.bool,
};
IntegrationsSection.defaultProps = {
  sectionData: {},
  integrationList: [],
  integrationOverride: false,
  showInt: false,
  showReq: false,
  isRetail: false,
};

export default IntegrationsSection;
