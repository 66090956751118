import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';
import Image from '../ImageQuerys/IntegrationImages';
import CtaSecondary from '../Buttons/secondary-cta';

const { Title } = Typography;

const IntegrationsContent = (props) => {
  const { title, subtext, contentImg, btnInfo, ImgClassName } = props;
  const { ctaTitle, ctaTarget } = btnInfo;
  return (
    <div className="integrations-boxes">
      <Row gutter={[64, 64]}>
        <Col lg={8} md={24} sm={24} xs={24}>
          <div className="bg-circle">
            <Image
              imageName={contentImg}
              className={` ${ImgClassName} integrations-boxes-img`}
            />
          </div>
        </Col>
        <Col lg={16} md={24}>
          <div>
            <Title level={3}> {title} </Title>
            <p>{subtext}</p>
            <CtaSecondary ctaTitle={ctaTitle} target={ctaTarget} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

IntegrationsContent.propTypes = {
  title: PropTypes.string,
  subtext: PropTypes.string,
  contentImg: PropTypes.string,
  ImgClassName: PropTypes.string,
  btnInfo: PropTypes.objectOf(PropTypes.any),
};
IntegrationsContent.defaultProps = {
  title: '',
  subtext: '',
  contentImg: '',
  btnInfo: '',
  ImgClassName: '',
};

export default IntegrationsContent;
