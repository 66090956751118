import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";
import Image from "../ImageQuerys/IntegrationImages";
import Heading from "../../components-v2/Base/HeadingBuilder";

const { Title } = Typography;

const IntegrationItem = (props) => {
  const { title, type, img } = props;

  return (
    <div className="integration-item">
      <div className="integration-item__image">
        <Image imageName={img} className="image-wrapper" />
      </div>
      <div className="mt-3 min-h-[24px]">
        <Heading level={3} injectionType={3} className="leading-[130%]  ">
          {title}
        </Heading>
      </div>
      <span>{type}</span>
    </div>
  );
};

IntegrationItem.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  img: PropTypes.string,
};
IntegrationItem.defaultProps = {
  title: "",
  type: "",
  img: "",
};

export default IntegrationItem;
